import React from 'react';
import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={"/logo/Icon.png"} className="App-logo" alt="logo" />
        <img src={"/logo/Name.png"} className='Name' alt="Name" />
        <img src={"/logo/Tagline.png"} className='Tagline' alt="Tagline" />
      </header>
    </div>
  );
}

export default App;